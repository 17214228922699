
<template>
  <div>
    <div
      v-permission="'contents.create'"
      class="flex flex-wrap-reverse items-center"
    >
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew"
      >
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <div>
      <Grid
        :hide_actions="false"
        :service="service"
        route_name="contents"
        :column_formats="{
          'type': (val) => $t(`content_types.${val}`),
          'release_at': (val) => $utils.format.date(val)
        }"
        :css_formats="{
          'type': (val) => val === 'A' ? 'text-warning' : 'text-success'
        }"
      >
        <template v-slot:gridActions>
          <vs-th key="actions">{{ $t('Actions') }}</vs-th>
        </template>
        <template v-slot:actions="actions">
          <feather-icon
            v-permission="`${actions.route_name}.edit`"
            icon="EditIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
            @click="actions.edit(actions.data[actions.rowindex].id)"
            v-tooltip.top-left="{
              content: 'Alterar conteúdo',
              delay: { show: 500, hide: 100 }
            }"
          />
          <feather-icon
            v-permission="`${actions.route_name}.delete`"
            icon="Trash2Icon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
            @click="actions.destroy(actions.data[actions.rowindex])"
            v-tooltip.top-left="{
              content: 'Excluir conteúdo',
              delay: { show: 500, hide: 100 }
            }"
          />
          <feather-icon
            :ref="`act${actions.rowindex}`"
            icon="LinkIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            @click="copyLink(actions.data[actions.rowindex], actions.rowindex, `act${actions.rowindex}`)"
            v-tooltip.top-left="{
              content: 'Copiar link do conteúdo',
              delay: { show: 500, hide: 100 }
            }"
          />
        </template>
      </Grid>
    </div>
  </div>
</template>


<script>
import ContentService from '@/services/api/ContentService'
import InfiniteGrid from '@/components/infiniteGrid.vue'
import DataViewer from '@/components/DataViewer.vue'
import ContentLink from '@/mixins/ContentLink'

export default {
  mixins: [
    ContentLink
  ],
  components: {
    InfiniteGrid,
    DataViewer
  },
  data: () => ({
    service: null,
  }),
  computed: {
    // PASS
  },
  methods: {
    addNew() {
      this.$router.push('/contents/create')
    },
  },
  beforeMount() {
    this.service = ContentService.build(this.$vs)
  },
}
</script>